.actionBox {
    margin: 10rem 1rem 1.5rem 0rem;
}

.actionBtn {
    gap: 10px;
    justify-content: flex-end;
}

.modalView {
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, .15);
}

.updateBtn {
    margin: 0.1rem 0rem 0rem 0.7rem;
    width: auto;
    background: linear-gradient(135deg, rgb(255, 88, 88), rgb(240, 152, 25));
    box-shadow: rgba(255, 88, 88, 0.5) 0px 2px 16px;
}

.noData {
    height: 20rem;
    width: 30rem;
    display: flex;
    margin: 5rem 2rem 2rem 22rem;
    border-radius: 5%;
}

.noDataText {
    margin: -1rem 2rem 2rem 32rem;
    font-size: x-large;
    font-weight: 700;
}

.circle {
    margin: 15rem 0rem 0rem 32rem;
}

.circleHight {
    height: 5rem;
    width: 5rem;
}

.testimonialEditBtn {
    background-color: white;
}

.BsPencilSquare {
    color: #0A5FD7;
    font-size: 1.2rem;
}

.testimonialDeleteBtn {
    background-color: white;
}

.MdDelete {
    color: red;
    font-size: 1.2rem;
}

.fileUploadBtn {
    background-color: white;
    color: black;
}

.FaFileUpload {
    color: forestgreen;
    font-size: 1.2rem;
}

.userEditBtn {
    background-color: white;
}

.BsPencilSquare {
    color: #0A5FD7;
    font-size: 1.2rem;
}

.useDeleteBtn {
    background-color: white;
}

.MdDelete {
    color: red;
    font-size: 1.2rem;
}

.editScholarshipBtn {
    background-color: white;
}

.BsPencilSquare {
    color: #0A5FD7;
    font-size: 1.2rem;
}

.deleteScholarshipBtn{
    background-color: white;
}
.MdDelete{
    color: red;
    font-size: 1.2rem;
}
.BsEyeFill{
    color: forestgreen ; 
    font-size: 1.2rem;
}
.admin-table .dropdown button {
    text-align: left;
}
.actionBox button {
    margin-right: 10px;
}
.w-10p {
    width: 10%;
}
.admin-createpage {
    margin-top: 7.5em;
}

.tablinks {
    border: 1px solid #ccc;
    margin-bottom: 0px;
    padding: 7px 23px;
    border-radius: 5px 5px 0 0;
    font-size: 15px;
    background: #fff;
    margin-bottom: -1px;
}
.tab-content {
    border: 1px solid #ccc;
    background: #fff;
}
.grid-frame {
    background: #fff;
    border: 1px solid #ccc;
}
/* Activity Calendar */
.fc .fc-toolbar-title {
    font-size: 19px;
}
.fc-today-button.fc-button.fc-button-primary {
    display: none;
}
.fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 10px;
}
a.fc-event.fc-event-start.fc-event-end.fc-event-past.fc-daygrid-event.fc-daygrid-block-event.fc-h-event, a.fc-event.fc-event-start.fc-event-end.fc-event-today.fc-daygrid-event.fc-daygrid-block-event.fc-h-event {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}
.a.fc-event.fc-event-start.fc-daygrid-event.fc-daygrid-block-event.fc-h-event, .fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc-direction-rtl .fc-daygrid-event.fc-event-start, .fc-direction-ltr .fc-daygrid-event.fc-event-start, .fc-direction-rtl .fc-daygrid-event.fc-event-end {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}
.fc-event {
    cursor: pointer;
}
.applicationDashboard {
    font-size: 13px !important;
    font-family: 'Open Sans' !important;
}
.close-captions {
    z-index: 1000;
    font-size: 18px;
    position: absolute;
    top: 15%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, 351%);
}