.scholarship-high {
    background-color: #d1e7dd;
    color: #198754;
    padding: 5px;
    border-radius: 5px;
    font-weight: normal;
    font-size: 12px;
}

.scholarship-mid {
    background-color: #fff3cd;
    color: #926e01;
    padding: 5px;
    border-radius: 5px;
    font-weight: normal;
    font-size: 12px;    
}

.scholarship-low {
    background-color: #f8d7da;
    color: #dc3545;
    padding: 5px;
    border-radius: 5px;
    font-weight: normal;
    font-size: 12px;
}

.subsection-head p {
    display: none;
}