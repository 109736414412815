@import "~react-image-gallery/styles/css/image-gallery.css";

.gallery-housing {
    width: 750px !important;
}

.custom-housing-btn{
    background-color: #DC7215;
    color:#fff;
    border:none;
    padding:7px 10px 7px 10px;
    border-radius: 5px;
    font-size: 13px;
}

.center-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}

.mainBox {
    border-radius: 8px;
    border: 1px solid #d1d5db;
}

.box_1 {
    background-color: white;
    border-radius: 8px 8px 0px 0px;
    border-bottom: 1px solid lightgray;
    margin-bottom: 1rem;

}

.box_2 {
    margin: 0px 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    flex-direction: row;
}

.mainBox_1 {
    display: flex;
    gap: 10px;
    margin: 1rem;
    justify-content: space-between;
}

.selectBox {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.cardBody {
    display: flex;
    flex-direction: column;
    margin: 10px 0px 10px 0px;
    gap: 5px;
}

.cardImg {
    width: 10rem;
    /* border-radius: 12px; */
    border-radius: 0px;
    padding: 10px;
}

.cardBtn {
    background-color: #DC7215;
    border-radius: 5px;
    padding: 8px 20px;
    border: none;
    color: white;
    font-size: medium;
    margin-right: 15px;
    font-weight: normal;
    font-size: 13px;
}

.cardBox {
    display: flex;
    gap: 12px;
    background-color: white;
    border: 1px solid lightgray;
    border-radius: 8px;
}

.room {
    background-color: rgb(243, 244, 246);
    padding: 2px 8px 2px 8px;
    border-radius: 4px;
    display: flex;
    gap: 4px;
}

.footerBox {
    display: flex;
    gap: 20px;
    margin: 0px 12rem 2rem 12rem;
}

.footerBox1 {
    display: flex;
    align-items: center;
    text-align: center;
    font-weight: 500;
    gap: 5px;
    border: 1px solid #e5e7eb;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    background: #ffffff;
    justify-content: center;
    padding: 5px 10px 5px 10px;
}

.roomImg {
    height: 15rem;
    width: 30rem;
    display: flex;
    margin: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
    border-radius: 1rem;
}

.facilityIcon {
    display: flex;
    gap: 5px;
}

.featureText {
    font-size: 11px;
}

.footerBoxIcon {
    color: rgb(15, 158, 110);
    font-size: 2rem;
}

.topSeparator {
    border-left: 1px solid lightgray;
}

.BiSearch {
    font-size: 1.3rem;
}

.locationName {
    margin-top: -8px;
    font-size: 12px;
    color: #6b7280;
}

.allFacilitiy {
    display: flex;
    gap: 5px;
    margin: 10px 0px 10px 0px;
}

.BiHotel {
    color: rgb(242, 71, 106);
    font-size: 1rem;
    margin-top: 2px
}

.MdOutlineLocalOffer {
    color: rgb(242, 71, 106);
    font-size: 1rem;
    margin-top: 4px
}
.priceName_1{
    display: flex; 
    align-items: center;
}

.priceName_2{
    display: flex ;
    margin: 0 10px 0 0;
    align-items: center;
}
.fromTag {
    font-size: 12px;
    margin-right: 5px;
}
.priceTag{
    font-size: 18px;
    color: black;
}
.roomBox{
    display: flex;
    gap: 30px;
}
.search-container {
    display: flex;
    align-items: center;
    gap: 10px;
}
.search-container select {
    width: auto;
}