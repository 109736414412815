/* form{
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
} */

.main_div {
    border: 1px solid rgba(8, 16, 16, .15);
    height: 22rem;
    width: 30rem;
    margin: auto;
    margin-top: 2rem;
    border-radius: 30px;
}

.top_text {
    font-weight: bold;
}

.signin {
    margin: 10px 0px 0px 10px;
    font-size: 22px;
    font-weight: 600;
}

.close_butn {
    margin: -32px 10px 0px 0px;
    text-align: right;
    font-size: 22px;
}

.site_login {
    justify-content: center;
    margin-top: 12px;
}

.auth-welcome {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 1rem;
    text-align: center;
}

h2 {
    color: #080f0fd9;
    font-size: 18px;
}

p {
    color: #080f0fbf;
}

.signin_form {
    display: flex;
    flex-direction: column;
}

.signin_input {
    background: #F2F5FA;
    border: 1px solid #0a1e321a;
    border-radius: 13px;
    box-sizing: border-box;
    color: #2c3d4f;
    display: flex;
    font-size: 15px;
    font-weight: 550;
    height: 45px;
    line-height: 1.5;
    margin: 5px auto;
    padding: 0 10px;
    width: 90%;
}

.sm-eye-icon {
    cursor: pointer;
    position: absolute;
    right: 30rem;
    top: 53.5%;
    width: 30px;
}

.forget-password {
    color: #f3912e;
    font-weight: 600;
    font-size: 15px;
    text-decoration: none;
    margin: 3rem 24px 1rem 0px;
    text-align: right;
}

.signin_input:focus{
    border: 1px solid #f3912e;
}


.forget-password1 {
    color: #f3912e;
    font-weight: 600;
    font-size: 15px;
    text-decoration: none;
    margin: -2rem 5px 0rem 0px;
    text-align: right;
}



.login_btn {
    background: #f3912e;
    border: none;
    border-radius: 12px;
    color: white;
    height: 40px;
    margin: 5px auto;
    opacity: 1;
    width: 90%;
}


.bottom_row {
    align-items: center;
    display: flex;
    gap: 2px;
    justify-content: center;
    margin: 10px 0;
    width: 100%;
}

.get_started {
    color: #f3912e;
    cursor: pointer;
    font-size: 15px;
    line-height: 1.5;
    -webkit-user-select: none;
    user-select: none;
}

.sm-saperator {
    border: 1px solid #22283133;
    display: flex;
    height: 1px;
    margin: 10px auto 25px;
    width: 70%;
}

.sm-saperator>span {
    background-color: white;
    color: #2c3d4f;
    font-size: 14px;
    font-weight: 700;
    height: auto;
    line-height: 18px;
    margin: -10px auto;
    width: 30px;
    text-align: center;
}
.sm-google-login{
    text-align: center;
}

.adminBtn{
    width: auto;
    /* background: linear-gradient(135deg, rgb(255, 88, 88), rgb(240, 152, 25)); */
    /* box-shadow: rgba(255, 88, 88, 0.5) 0px 2px 16px; */
}

.main_div_1{
    border: 1px solid rgba(8, 16, 16, .15);
    height: 22.5rem;
    width: 30rem;
    margin: auto;
    margin-top: 2rem;
    border-radius: 30px;
}

.forgot_btn{
    background: #f3912e;
    border: none;
    border-radius: 12px;
    color: white;
    height: 40px;
    margin: 5px 1.5rem;
    opacity: 1;
    width: 90%;
}

.page-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
}

.admin-table {
    font-size: 13px;
    margin-bottom: 0;
}
.pagination {
    margin-top: 10px;
}
.mainHeading{
    margin-top: 50px;
    font-size: 25px;
    color: #534173;
    font-weight: 600;
    display: flex;
    justify-content: center;
}
.regularText{
    font-size: 20px;
    color: #534173;
    justify-content: center;
    display: flex;
}
#toggle-pass {
    height: 30px;
    width: 2.5rem;
    margin: -2.7rem 0px 0px 26rem;
    border: 0;
    background: transparent;
}