.grid-list-notoggle {
    background: #fff;
    border: 1px solid #d0d5dd;
    border-radius: 5px;
    box-shadow: 2px 4px 8px rgba(0,0,0,.1);
    float: left;
    list-style-type: none;
    margin: 0 0 20px;
    padding: 20px;
    width: 100%;
}
.grid-list-pagination {
    float: left;
    list-style-type: none;
    margin: 0 0 20px;
    padding: 20px;
    width: 100%;
}
.hd-xl {
    font-size: 18px;
    font-weight: 600;
}
.card-body h3 {
    font-size: 17px;
}
.highlighted-list {
    box-shadow: 2px 4px 8px rgba(251,163,60,1);
}