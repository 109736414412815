@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap');

:root {
  --height: 100%;
  --width: 100%;
}

.controls {
  position: absolute;
  z-index: 3;
  height: var(--height);
  width: var(--width);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.frame {
  height: 100%;
  width: 100%;
  position: relative;
}

.overlay {
  position: absolute;
  z-index: 2;
  height: var(--height);
  width: var(--width);
}

.canvas {
  position: absolute !important;
  z-index: 1;
  height: var(--height) !important;
  width: var(--width) !important;
}

.control-div {
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
}

.seekbar {
  width: 85%;
  background: transparent;
  margin: 0 10px;
}

.seekbar {
  -webkit-appearance: none;
}

.seekbar::-webkit-slider-runnable-track {
  background: #534173;
}

.seekbar::-webkit-slider-thumb {
  background: #fba33c;
}

.seekbar:focus {
  outline: none;
}

button.btn-playpause {
  background: rgba(0, 0, 0, 0.5);
  border: 0;
  color: #fff;
  border-radius: 5px;
  margin: 5px 0 5px 5px;
  padding: 5px;
  width: 40px;
  font-size: 12px;
}

.video-time {
  top: 10px;
  position: relative;
  color: #fff;
}

.videoselected-thumb {
  display: flex;
  flex-wrap: wrap;
}
.videoselected-thumb button {
  margin: 10px 0;
  background: transparent;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.videoselected-thumb button.chapter-active {
  background: #dc7215;
  color: #fff;
}
.chapter-active {
  border: 1px solid #534173 !important;
}
.videoselected-thumb div.item-container {
  margin: 10px 10px 10px 0;
  padding: 10px;
  background: #f3f3f3;
  float: left;
  width: 19%;
}
.videoselected-thumb p {
  margin-bottom: 5px;
}
.videoselected-thumb .item-name {
  font-size: 15px;
  font-weight: 600;
}
.thumb-countryimage {
  width: 100px;
  height: 45px;
  margin-top: 10px;
}
.videoselected-thumb p.tagline.elipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cta-courseSelection {
  margin-top: -45px;
}
.fa-medal {
  padding: 0 5px;
  font-size: 16px;
}
.gold {
  color: #af9500;
}
.silver {
  color: #b4b4b4;
}
.bronze {
  color: #ad8a56;
}
span.coming-soon {
  position: absolute;
  margin-top: 23px;
  margin-left: -110px;
  font-size: 9px;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

body {
  height:100%;
  width:100%;
  background-color:#EFEDE9;  
  font-family: 'Open Sans', sans-serif;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 240px;
  height: 30px;
  padding: 10px 20px;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input:focus {
  outline: 2px solid #dc7215;
  border: none;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: none;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.react-autosuggest__section-container {
  border-top: 1px dashed #ccc;
}

.react-autosuggest__section-container--first {
  border-top: 0;
}

.react-autosuggest__section-title {
  padding: 10px 0 0 10px;
  font-size: 12px;
  color: #777;
}

.sortTile_container {
  cursor: pointer;
  gap:1rem;
  background: #f3f3f3;
  border-radius: 5px;
}
.sortTile_container:active {
  cursor: grabbing;
  background: rgba(255,246,239);
}
.sortTile_h2 {
  background-color: #dc7215;
  color: #fff;
  font-size: 15px;
  margin: 0;
  height: 100%;
  padding: 5px 0;
  width: 30px;
  text-align: center;
}
.sortTile_h1 {
  font-weight: 500;
  color: #dc7215;
  font-size: 16px;
}
.countryTile_container{
  flex-wrap: wrap;
  width:250px;
  display: flex;
  flex-direction: column;
  border:4px solid #dc7215;
  border-radius: 10px;
}

.countryTile_tag {
  position:relative;;
  top:-1.65rem;
  background-color: #dc7215;
  color:#fff;
  border-radius: 50%;
  padding:0.5rem 1.2rem;
  font-size: 24px;
}
.countryTile_h1{
  font-size: 21px;
  white-space: pre-wrap;
  text-align: center;
  margin-bottom: 1em;
}

.countryTile_score{
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  color:#777
}
.course-selected {
  padding:5px 8px;
  border: 1px solid #534173;
  border-radius: 10px;
}
.drag-icon {
  position: relative;
  left: 8px;
}
.drag-icon i {
  color: #ccc;
  cursor: move;
}