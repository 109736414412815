.w-100p {
    width: 100%;
}
.widget-hd {
    background: #F2F4F7;
    padding: 10px;
    font-weight: 600;
}
.widget-border {
    border: 1px solid #98A2B3;
}
.test-eligibility {
    background: #fff;
    box-shadow: 1.673px 3.345px 6.691px 0px rgba(0, 0, 0, 0.10);
    border: 1px solid #D0D5DD;
    width: auto;
}
.test-eligibility-form {
    padding: 20px;
}
.test-eligibility-widget {
    background: #fff;
    box-shadow: 1.673px 3.345px 6.691px 0px rgba(0, 0, 0, 0.10);
    border: 1px solid #D0D5DD;
    margin-right: 20px;
}
ul.test-eligibility-options {
    display: flex;
   flex-wrap: wrap;
}
.test-eligibility-options li {
    list-style-type: none ;
}
.test-name {
    padding: 10px;
    font-weight: 600;
    border-bottom: 1px solid #D0D5DD;
}
.test-about {
    padding: 10px;
    font-size: 13px;
}
.test-about ul {
    margin: 0;
    padding: 0;
}
.test-about li {
    font-size: 12px;
    margin-left: 20px;
}
.info-panel {
    width: 50%;
    height: 750px;
    overflow-y: auto;
    padding: 10px;
}
.questions-panel {
    width: 50%;
    background: #fcfcfc;
    padding: 10px;
}
.questions-panel ul li {
    list-style-type: none;
    margin-left: 20px;
    font-size: 13px;
}
.questions {
    margin-top: 25px;
}
.test-input {
    border: 0;
    background: #ccc;
    border-radius: 0;
    width: 25%;
    color: #000;
}
/* IELTS */
.itels-reading-answersheet-top > ul {
    text-align: center;
}
.itels-reading-answersheet-top > ul li {
    display: inline-block;
}
.black-block {
    width: 30px;
    height: 30px;
    display: inline-block;
    background: #000 ;
}
.answersheet-heading {
    text-align: center;
    font-size: 20px;
}
.itels-reading-answersheet-bottom {
    border: 10px solid #212b85;
    padding: 10px;
}
.course-progress {
    border-radius: 5px;
    border: 1px solid #475467;
    background: #FFF;
    padding: 5px 10px;
    font-size: 14px;
    margin-bottom: 20px;
    display: flex;
    width: fit-content;
    color: #667085;
}
.course-content {
    border-radius: 10px;
    background: #fff;
}
.course-content-description {
    padding: 10px;
    background: #EAECF0;
    border-radius: 10px 10px 0 0;
}
.course-content-description p {
    margin-bottom: 0;
    font-weight: 600;
}
.course-content .accordion .accordion-item {
    border-radius: 0;
}
.course-content .accordion .accordion-header button {
    background: transparent;
    color: #101828;
    font-weight: 600;
    font-size: 14px;
    padding: 10px;
}
.course-content .accordion .accordion-header button .section-heading {
    padding-right: 10px;
}
.course-content .accordion .accordion-header button small {
    font-weight: 200;
    display: contents;
}
.course-content .accordion .accordion-body {
    padding: 0px;
}
.course-content .accordion .accordion-body ul {
    margin: 0;
    padding: 0;
}
.course-content .accordion .accordion-body ul li {
    list-style-type: none;
    font-size: 12px;
    border-bottom: 1px solid #D0D5DD;
    padding: 10px;
}
.course-content .accordion .accordion-body ul li:last-child {
    border-bottom: 0;
}
.course-content .accordion .accordion-body ul li:hover, .course-content .accordion .accordion-body ul li.active-chapter {
    background: rgba(175, 112, 41, 0.16);
    cursor: pointer;
}
.course-details .nav-pills {
    border-radius: 5px;
    border: 1px solid #667085;
    background: #FFF;
    box-shadow: 2px 5px 10px 0px rgba(0, 0, 0, 0.10);
}
.course-details .nav-pills .nav-link {
    font-size: 15px;
    color: #475467;
}
.course-details .nav-pills .nav-link.active {
    background: transparent;
    color: #FBA33C;
}
.create-note {
    border: 1px solid #ccc;
    text-align: right;
    padding: 5px;
    font-size: 14px;
    background: #fff;
}
.create-note-content textarea {
    margin: 20px 0 10px 0;
}
.course-rating {
    font-size: 50px;
    color: #DC7215;
}
.course-review-comment {
    width: 100%;
    margin-left: 20px;
}
.course-review-comment textarea{
    margin-bottom: 10px;
}
.fa-star.checked {
    color: #DC7215;
}
.completed-assessment {
    position: absolute;
    font-size: 7px;
    font-weight: 600;
    margin-top: 16px;
    margin-left: 23px;
}
.review-list ul {
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    margin-bottom: 10px;
}
.review-list ul li {
    list-style-type: none;
    font-size: 13px;
    padding-bottom: 5px;
}
span.name-icon {
    border-radius: 50%;
    background: #000;
    padding: 5px 10px;
    color: #fff;
    float: left;
    margin-right: 10px;
    font-size: 13px;
}