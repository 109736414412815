[class*='mentor-col-'] {
    float: left;
    padding: 20px;
    font-family: 'Open Sans', sans-serif, Arial, Helvetica;
}
.mentor-col-2 {
    width: 50%;
}
.mentor-col-3 {
    width: 33.33%;
}
.mentor-col-38 {
    width: 38.2%;
}
.mentor-col-1-3 {
    width: 33.33%;
}
.mentor-col-2-3 {
    width: 66.66%;
}
.mentor-col-3-4 {
    width: 75%;
}
.mentor-icon i {
    color: #ca9900;
}
.mentor-insidepage {
    margin-top: 100px;
}
.mentor-icon-block-description p {
    font-size: 15px;
    font-weight: normal;
    color: #111;
}
.mentor-icon-block-description h4 {
    font-size: 18px;
    font-weight: 700;
    color: #111;
    padding: 7px 0;
}
.mentor-button {
    font-size: 16px;
    margin: 35px 0;
    padding: 11px 16px;
    -webkit-transition: all .3s linear;
    -moz-transition: all .3s linear;
    transition: all .3s linear;
    display: inline-block;
    border-width: 3px;
    border-style: solid;
    border-color: #000;
    color: #000;    
}
.mentor-button:hover {
    color: #ca9900;
    border-color: #ca9900;
    text-decoration: none;
}
.mentor-button-sm {
    font-size: 14px;
    font-weight: 600;
    margin: 15px 0;
    padding: 5px 10px;
    -webkit-transition: all .3s linear;
    -moz-transition: all .3s linear;
    transition: all .3s linear;
    display: inline-block;
    border-width: 2px;
    border-style: solid;
    color: #ca9900;
    border-color: #ca9900;
    background: transparent;
}
.mentor-button-sm:hover {
    border-color: #000;
    color: #000;
}
.mentor-section-title {
    font-size: 34px; 
    font-weight:700;
    color:#111;
    padding: 15px 0;
}
.mentor-section-subtitle {
    font-size: 16px;
    font-weight:300;
    color:#9c9c9c;
}
#banner {
    background: url("../images/banner-images/banner-image-1.jpg") no-repeat center top;
    background-size: cover;
}
#banner-content.row {
    padding-top: 85px !important;
    padding-bottom: 100px;
}
#banner h1 {
    padding-top: 5%;
    font-size:50px;
    line-height:60px;
    font-weight:800;
    color:#111;
    padding-bottom: 20px;
}
#banner .mentor-section-heading:before {
    background: #ca9900;
}
.mentor-section-heading:before {
    content: "";
    display: block;
    width: 100px;
    height: 5px;
    margin-top: 30px;
}
.text-center .mentor-section-heading h2:after {
    margin: 30px auto 0 auto;
}
#banner h2 {
    font-size: 18px;
    font-weight: 300;
    color: #111;
}
.mentor-section-heading h2:after {
    background: #ca9900;
    content: "";
    display: block;
    width: 100px;
    height: 5px;
    margin-top: 30px;
}
.mentor-section-heading h3 {
    font-size: 14px;
    font-weight: bold;
    color: #ccc;
    letter-spacing: 2px;
    padding-bottom: 0;
}
.testimonial.classic q, .testimonial.classic footer {
    color: #111;
}
.testimonial.classic q {
    display: block;
}
.testimonial q {
    font-size: 17px;
    font-weight: 300;
}
.testimonial.classic q, .testimonial.classic footer {
    color: #111;
}
.testimonial footer {
    padding-top: 12px;
}
.testimonial.classic footer:before {
    background: #ca9900;
}
.testimonial.classic footer:before {
    display: block;
    content: "";
    width: 100px;
    height: 4px;
    margin: 10px auto 15px auto;
}
.tags {
    margin-top: 10px;
}
.tags .tag {
    background: #CA9900;
    font-size: 11px;
    border-radius: 10px;
    padding: 5px 10px;
    color: #fff;
    margin-bottom: 10px;
    display: inline-block;
    margin-right: 5px;
}
#landing-footer, #landing-footer p, #landing-footer a {
    font-size: 12px;
    font-weight: normal;
    color: #999;
}
#landing-footer {
    clear: both;
    background: #efefef;
}
#landing-footer ul {
    text-align: right;
}
#landing-footer ul.col-2.social-icons {
    position: relative;
    right: 60px;
    display: inline-block;
    list-style: none;
}
#landing-footer li {
    display: inline-block;
}
#landing-footer .social-icons span {
    display: none;
}
#landing-footer, #landing-footer p, #landing-footer a {
    font-family: 'Open Sans', sans-serif, Arial, Helvetica;
    font-size: 12px;
    font-weight: normal;
    color: #999;
}
#landing-footer .social-icons a {
    display: inline-block;
    width: 32px;
    text-align: center;
}
.testimonial.classic img {
    display: inline-block;
    margin-bottom: 25px;
}
.testimonial img {
    height: 8em;
    border-radius: 50%;
    width: 8em;
}
.calendar-container table {
    font-size: 12px;
}
.calendar-container table .form-control {
    width: auto;
}
.pricing-block-content {
    background: #fff;
    padding: 25px 0 25px 0;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.07);
    transition: all 0.2s ease-in-out 0s;
    position: relative;
    border: 10px solid #f5f5f5;
}
.pricing-block-content:hover {
    border-color: #ca9900;
}
#pricing, #checkout {
    font-family: 'Open Sans', sans-serif, Arial, Helvetica;
}
.pricing-block h3 {
    font-size: 17px;
    text-transform: uppercase;
    padding-bottom: 0;
}
.pricing-sub {
    font-style: italic;
    color: #ccc;
    margin: 0 0 25px 0;
}
.pricing {
    background: #f5f5f5;
    padding: 15px;
    position: relative;
}
.pricing-block ul {
    list-style: none;
    margin: 25px 0 25px 0;
    padding: 0 10px;
}
.pricing-block li {
    padding: 10px 0;
    border-bottom: 1px dotted #e1e1e1;
}
.price {
    display: inline-block;
    font-size: 30px;
    font-weight: 400;
    position: relative;
}
.price span {
    position: absolute;
    top: 0;
    left: -20px;
    font-size: 22px;
}
.featured .pricing {
    background-color: #ca9900;
}
.featured .pricing, .featured .pricing p {
    color: #fff;
}
.checkout-container {
    width: 50%;
    margin: 0 auto;
    margin-top: 150px;
    border: 2px solid #ca9900;
    padding: 20px;
    border-radius: 10px;
    min-height: 500px;
    margin-bottom: 100px;
}
.checkout-paybutton {
    display: flex;
    flex-direction: column;
}
.chat-bubble {
    border-radius: 10px;
    padding: 10px;
    font-size: 13px;
    margin-bottom: 20px;
}
.mentorchat-panel {
    position: absolute;
    right: -10px;
    width: 30%;
    top: 0;
    z-index: 9999;
    background-color: #ccc;
}
.chat-container {
    height: 75vh;
    overflow-y: auto;
}
.mentor-profile-mg {
    height: 30px;
    width: 30px;
}
.mentor-image-thumb i {
    font-size: 100px;
}
@media only screen and (min-width: 320px) and (max-width: 812px) {
    .mentor-col-38, .mentor-col-3, .mentor-col-2, .mentor-col-2-3, .mentor-col-1-3 {
        width: 100%;
    }
    #banner-content.row {
        padding: 0 !important;
        top: 20px;
        position: relative;
    }
    .mentor-button {
        margin: 0;
        position: relative;
        top: 55px;
        background: #ca9900;
        color: #fff;
        border-width: 1px;
    }
    .mentor-section-heading h2:after {
        display: none;
    }
    #banner h1 {
        font-size: 40px;
    }
    #banner h2 {
        position: relative;
        top: 50px;
    }
    .header-nav p {
        margin-bottom: 0;
        font-size: 13px;
    }
    .mentor-col-2.mentor-icon-block.mentor-icon-top.wow.fadeInUp {
        border-bottom: 1px solid #ddd;
    }
    .mentorchat-panel {
        right: auto;
        width: auto;
        padding-bottom: 50px;
    }
}