.programMainBox_1 {
    display: flex;
    gap: 2rem;
    margin: 2rem 0rem 0rem 2rem;
}

.filterBox {
    border-radius: 8px;
    padding: 0.5rem 1rem 2rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    height: 38rem;
    box-shadow: 2px 4px 8px rgba(0, 0, 0, .1);
    background-color: white;
}
.filterBox2 {
    border-radius: 8px;
    padding: 0.5rem 1rem 2rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    height: 32rem;
    box-shadow: 2px 4px 8px rgba(0, 0, 0, .1);
    background-color: white;
}

.filterSelectBox {
    width: 18rem;
    color: black;
    border-radius: 3.5px;
}

.programBox {
    border: 1px solid lightgrey;
    border-radius: 8px;
    padding: 1rem 1rem 0rem 1rem;
    display: flex;
    flex-direction: column;
    box-shadow: 2px 4px 8px rgba(0, 0, 0, .1);
}

.programBox_1 {
    border: 1px solid lightgrey;
    border-radius: 8px;
    background-color: white;
    padding: 1rem 1rem 2rem 2rem;
    margin-top: 1rem;
}

.programBox_2 {
    display: flex;
    flex-direction: column;
    margin: 1.5rem 0rem 0rem 0rem;
    gap: 2rem;
}

.programHeading {
    display: flex;
    gap: 5rem;
}

.feeData {
    display: flex;
    gap: 21rem;
}

.eligibilityShowBox {
    display: flex;
    gap: 11rem;
}

.eligibleBtn {
    border: none;
    color: #f3912e;
    background-color: white;
}

.filterBox_1 {
    border: 1px solid lightgrey;
    border-radius: 8px;
    padding: 0.5rem 1rem 2rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    box-shadow: 2px 4px 8px rgba(0, 0, 0, .1);
    height: 2rem;
    background-color: white;
}

.schoolShowBox {
    display: flex;
    gap: 7.3rem;
}

.courseShowBox {
    display: flex;
    gap: 8.7rem;
}

.allFilters {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 1rem 0rem 0rem 3rem;
}

.programSearchBox {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    margin: 6rem 5rem 0rem 0rem;
}

.programSearch {
    width: 25rem;
    margin: 0rem 0rem 2rem 36.4rem;
}

.programSearchBtn {
    background-color: rgb(220, 114, 21);
    border-radius: 6px;
    padding: 4px 20px 4px 20px;
    border: none;
    color: white;
    font-size: medium;
    margin-right: 15px;
}

.programSortBy {
    display: flex;
    justify-content: space-between;
}

.programDetailBtn{
    background-color: rgb(220, 114, 21);
    border-radius: 6px;
    padding: 4px 15px 4px 15px;
    border: none;
    color: white;
}
.programDetails{
    display: flex;
    justify-content: end;
    margin: 0px 2rem;
}