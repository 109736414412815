/* Shortlisting */
.shortlisting-list {
    margin-bottom: 10px;
    background: #FFFFFF;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 20px !important;
}

.shortlisting-list li {
    margin-bottom: 10px;
}

.shortlisting-list i {
    color: rgba(251, 163, 60, 1);
}

.shortlisting-details-content {
    padding: 0 25px;
    background: #FFFFFF;
    box-shadow: 0px 8px 24px 2px rgba(0, 0, 0, 0.18);
    width: 75%;
    margin-top: 75px;
}

.shortlisting-details-table td {
    font-size: 13px;
}

.details-card .card {
    margin-top: 20px;
    margin-right: 20px;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.15);
}

.details-card .card .card-body p:first-child {
    font-size: 11px;
}

.details-card .card .card-body p:last-child {
    margin-bottom: 0;
    font-size: 15px;
    font-weight: 600;
}

.shortlisting-name {
    padding: 20px 0;
}

.shortlisting-institution-logo img {
    width: 50%;
}

.noDataSortList {
    height: 15rem;
    width: 20rem;
    display: flex;
    margin: 5rem 0rem 1rem 20rem;
    border-radius: 5%;
}
.noDataTextSortList {
    margin: 0rem 2rem 2rem 25rem;
    font-size: x-large;
    font-weight: 700;
}

.circleHight_1 {
    width: 60px;
    height: 60px;
}

.circle_1 {
    margin: 10rem 0rem 0rem 26rem;
}