.hr-sep {
  border: 1px dashed #ccc;
}
.cursor-link {
  cursor: pointer;
}
.dashboard h1 {
    font-weight: normal;
    font-size: 25px;
}
.no-leftnav {
    margin-left: 0 !important;
}
.no-leftnav header {
  margin-left: 0 !important;
}
.user-fletter-avatar {
    border: 1px solid #DC7215;
    border-radius: 50%;
    padding: 5px 10px;
    text-align: center;
    float: left;
    font-weight: 600;
    font-size: 13px;
    margin-left: 10px;
    margin-right: 10px;
    color: #DC7215;
}
.todays-date {
    font-size: 13px;
    color: #333;
}
.weather-widget {
    background: #fff;
    border-radius: 10px;
    padding: 10px;
    font-size: 12px;
    margin-right: 15px;
}
.weather-widget p {
  margin-bottom: 0;
  font-size: 12px;
}
.weather-info {
  margin-top: 10px;
  text-align: right;
}
.dashboard-widget-frame {
    background: #fff;
    padding: 10px;
    margin: 10px 0px;
    border-radius: 10px;
    width: 31%;
    position: relative;
    overflow: hidden;
    box-shadow: 2px 2px 20px #EEE;
    transition: transform .25s;
}
.dashboard-widget-frame:hover {
  box-shadow: 5px 5px 5px #ccc;
}
.dashboard-widget-frame-nozoom:hover {
  transform: scale(1);
}
.dashboard-rightpanel {
  background: #eee;
  border-radius: 0 10px 10px 0;
}
.dashboard-rightpanel .dashboard-widget-frame {
  width: auto;
}
.dashboard-widget-frame .widget-title {
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 0px;
}
.dashboard-widget-frame-2x {
  width: 64.5%;
}
.dashboard-widget-frame-3x {
  width: 98.5%;
}
.orange-widget {
    background: #DC7215;
    color: #fff;
}
.widget-subtitle {
    font-size: 13px;
}
.widget-cta {
    background: #DC7215;
    color: #fff;
    border-radius: 10px;
    border: 0;
    font-size: 13px;
    padding: 5px 10px;
}
.welcome-user {
    border-radius: 20px;
    padding: 20px 0 10px 0;
    background: #FFFBF0;
    position: relative;
    margin-left: 10px;
    width: 100%;
}
.book-now-topright {
    position: absolute;
    top: 0;
    right: 0;
}
.book-now-leftbottom {
    position: absolute;
    bottom: 0;
    left: 0;
}
.book-now-content {
    width: 90%;
    margin: 0 auto;
}
.book-now-content h1 {
    color: #000;
    font-weight: 600;
}
.book-now-content p {
    margin: 25px 0;
}

.arrow {
    cursor: pointer;
    height: 30px;
    transform: translateX(-50%) translateY(-50%);
    transition: transform 0.1s;
    width: 30px;
    position: relative;
    margin-top: 10%;
    left: 15px;
  }
  .arrow-left {
    transform: rotate(180deg);
    left: 0px;
    margin-top: 8%;
  }  
  .arrow-top, .arrow-bottom {
    background-color: #666;
    height: 2px;
    left: -5px;
    position: absolute;
    top: 50%;
    width: 100%;
  }
  .arrow-top:after, .arrow-bottom:after {
    background-color: #DC7215;
    content: "";
    height: 100%;
    position: absolute;
    top: 0;
    transition: all 0.15s;
  }
  .arrow-top {
    transform: rotate(45deg);
    transform-origin: bottom right;
  }
  .arrow-top:after {
    left: 100%;
    right: 0;
    transition-delay: 0s;
  }
  .arrow-bottom {
    transform: rotate(-45deg);
    transform-origin: top right;
  }
  .arrow-bottom:after {
    left: 0;
    right: 100%;
    transition-delay: 0.15s;
  }
  .arrow:hover .arrow-top:after {
    left: 0;
    transition-delay: 0.15s;
  }
  .arrow:hover .arrow-bottom:after {
    right: 0;
    transition-delay: 0s;
  }

  .user-name {
    color: #000;
  }
  
  #progressbar {
    background-color: #eee;
    border-radius: 13px;
    /* (height of inner div) / 2 + padding */
    padding: 3px;
  }
  
  #progressbar>div {
    background-color: #15db6e;
    /* Adjust with JavaScript */
    height: 10px;
    border-radius: 10px;
  }

  .application-status {
    margin: 0;
    padding: 0;
    margin-top: -20px;
  }
  .application-status li {
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-size: 12px;
    float: left;
    width: auto;
    margin-right: 10px;
    padding-bottom: 5px;
  }
  .application-status p {
    margin-bottom: 0;
    font-size: 11px;
  }
  .application-status span {
    margin-top: 5px;
  }
  .application-status li p strong {
    color: #DC7215;
  }
  .application-status + .button-alignment {
    position: inherit;
  }
  .weather-temp {
    padding: 0;
    margin: 0;
    font-size: 12px;
    font-weight: 600;
    border-bottom: 1px dashed #ccc;
    padding-bottom: 1px;
    border-radius: 0;
  }

  .counseling-tile {
    background: #fff url('../Media/Images/char_animation/Counseling.gif') right bottom no-repeat;
    background-size: 50%;
  }
  .counseling-list {
    margin: 0;
    padding: 0;
  }
  .counseling-list li {
    list-style-type: none;
    float: left;
    margin: 10px 0;
    background: #FFFBF0;
    border: 1px solid #ddd;
    padding: 5px;
    text-align: center;
    width: 30%;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .counseling-list li:last-child {
    border-left: 0;
  }
  .counseling-list li span {
    font-size: 10px;
    line-height: 12px;
    display: flex;
    font-weight: 600;
    padding-bottom: 10px;
  }
  .counseling-list li img.country {
    width: 45%;
  }
  .counseling-list li img.course {
    width: 39%;
  }

  .shortlisting-tile .widget-cta {
    width: max-content;
  }
  .shortlisting-tile .shortlisting {
    width: 50%;
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .document-tile {
    background: #fff url('../Media/Images/char_animation/Applications.gif') right bottom no-repeat;
    background-size: 65%;
  }

  .loan-tile {
    background: #fff url('../Media/Images/char_animation/Finance.gif') right bottom no-repeat;
    background-size: 65%;
  }

  .coaching-tile {
    background: #fff url('../Media/Images/char_animation/Coaching.gif') right bottom no-repeat;
    background-size: 65%;
  }

  .affiliate-tile {
    margin-left: 30px;
  }

  .dashboard-tile-category ul {
    margin: 0;
    padding: 0;
  }
  .dashboard-tile-category li {
    float: left;
    list-style-type: none;
    padding-right: 8px;
    margin-right: 8px;
    border-right: 1px solid #eee;
  }
  .dashboard-tile-category li:last-child {
    padding-right: 0;
    margin-right: 0;
    border-right: 0;
  }
  .dashboard-tile-category-multi li {
    width: 32%;
    margin-bottom: 20px;
  }
  .dashboard-tile-category-multi li:nth-child(3) {
    border-right: 0;
  }
  .predeparture-tile {
    background: #fff url('../Media/Images/char_animation/predeparture.gif') right bottom no-repeat;
    background-size: 30%;
    min-height: 210px;
  }
  .postdeparture-tile {
    background: #fff url('../Media/Images/char_animation/postdeparture.gif') right bottom no-repeat;
    background-size: 50%;
  }
  .application-status .Pending {
    background: url('../Media/Images/status-pending.png') no-repeat;
    height: 28px;
    width: 23px;
    background-size: 100%;
    display: block;
  }
  .application-status .Shortlisted {
    background: url('../Media/Images/status-shortlisted.png') no-repeat;
    height: 28px;
    width: 28px;
    background-size: 100%;
    display: block;
  }
  .application-status .Applied {
    background: url('../Media/Images/status-applied.png') no-repeat;
    height: 28px;
    width: 28px;
    background-size: 100%;
    display: block;
  }
  .application-status .Pre-Application {
    background: url('../Media/Images/status-preapplicaion.png') no-repeat;
    height: 28px;
    width: 28px;
    background-size: 100%;
    display: block;
  }
  .application-status .Submitted {
    background: url('../Media/Images/status-submitted.png') no-repeat;
    height: 28px;
    width: 28px;
    background-size: 100%;
    display: block;
  }
  .application-status .Under, .application-status .Waitlisted {
    background: url('../Media/Images/status-underreview.png') no-repeat;
    height: 28px;
    width: 28px;
    background-size: 100%;
    display: block;
  }
  .application-status .Post-Application {
    background: url('../Media/Images/status-postapplication.png') no-repeat;
        height: 28px;
    width: 28px;
    background-size: 100%;
    display: block;
  }
  .application-status .Offer {
    background: url('../Media/Images/status-offer.png') no-repeat;
    height: 28px;
    width: 26px;
    background-size: 100%;
    display: block;
  }
  .application-status .Rejected {
    background: url('../Media/Images/status-rejected.png') no-repeat;
    height: 28px;
    width: 28px;
    background-size: 100%;
    display: block;
  }
  .application-status .Cancelled {
    background: url('../Media/Images/status-cancelled.png') no-repeat;
    height: 28px;
    width: 28px;
    background-size: 100%;
    display: block;
  }
  .application-status .no-application {
    border: 0;
  }
  .btn-cta-lg {
    background: #fff;
  }
  .btn-cta-lg:hover {
    background: #ddd;
    color: #000;
  }
  .button-alignment-posAbs {
    position: absolute;
    bottom: 0px;
  }
  .button-alignment {
    position: absolute;
    bottom: 10px;
  }
  .button-alignment-noPos {
    position: initial;
  }
  .referral-form label {
    color: #000;
    margin-left: 10px;
  }

  .label-white {
    color: #fff !important;
    margin-left: 0px !important;
  }

  .referral-form .app-multiSelect {
    color: #000;
  }