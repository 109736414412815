.page-container.sidebar-collapsed {
padding-right: 0;
transition: all 100ms linear;
transition-delay: 300ms;
}

.page-container.sidebar-collapsed-back {
padding-right: 0;
transition: all 100ms linear;
}

.page-container.sidebar-collapsed .sidebar-menu {
width: 75px;
transition: all 100ms ease-in-out;
transition-delay: 300ms;
}

.page-container.sidebar-collapsed-back .sidebar-menu {
width: 250px;
transition: all 100ms ease-in-out;
}

.page-container.sidebar-collapsed .sidebar-icon {
transform: rotate(90deg);
transition: all 300ms ease-in-out;
}

.page-container.sidebar-collapsed-back .sidebar-icon {
transform: rotate(0deg);
transition: all 300ms ease-in-out;
}

.page-container.sidebar-collapsed .logo {
padding: 20px;
box-sizing: border-box;
transition: all 100ms ease-in-out;
transition-delay: 300ms;
}

.page-container.sidebar-collapsed-back .logo {
width: 100%;
padding: 20px;
box-sizing: border-box;
transition: all 100ms ease-in-out;
}

.page-container.sidebar-collapsed #logo {
opacity: 0;
transition: all 200ms ease-in-out;
}

.page-container.sidebar-collapsed-back #logo {
opacity: 1;
transition: all 200ms ease-in-out;
transition-delay: 300ms;
}

.page-container.sidebar-collapsed #menu span {
opacity: 0;
transition: all 50ms linear;
}

.page-container.sidebar-collapsed-back #menu span {
opacity: 1;
transition: all 200ms linear;
transition-delay: 300ms;
padding-left: 10px;
}

.sidebar-menu {
position: fixed;
float: left;
width: 280px;
top: 0;
left: 0;
bottom: 0;
background-color: #5B79A1;
color: #fff;
z-index: 1031;
}

#menu {
list-style: none;
margin: 0;
padding: 0;
margin-bottom: 20px;
}

#menu li {
position: relative;
margin: 0;
margin-top: 25px;
padding: 0;
}

#menu li ul {
opacity: 0;
height: 0px;
}

#menu li a {
font-style: normal;
font-weight: 400;
position: relative;
display: block;
padding: 10px 0 10px 15px;
color: #fff;
white-space: nowrap;
z-index: 2;
font-size: 20px;
overflow: hidden;
}

#menu li a:hover {
color: #ffffff;
background: rgba(0, 0, 0, 0.5);
transition: color 250ms ease-in-out, background-color 250ms ease-in-out;
text-decoration: none;
}

#menu li.active > a {
background-color: #2b303a;
color: #ffffff;
}

#menu ul li { 
    background-color: #2b303a; 
}

#menu ul {
list-style-type: none;
margin: 0;
padding: 0;
}

#menu li ul {
position: absolute;
visibility: hidden;
left: 100%;
top: 0px;
background-color: #2b303a;
box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
opacity: 0;
transition: opacity 0.1s linear;
width: 100%;
}

#menu li ul li {
    margin-top:  0;
    background-color: #5B79A1;
    width: 100%;
}

#menu li ul li a {
    padding: 12px 20px 13px 10px;
    font-size: 17px;
}

#menu li:hover > ul {
visibility: visible;
opacity: 1;
}

#menu li li ul {
left: 100%;
visibility: hidden;
top: -1px;
opacity: 0;
transition: opacity 0.1s linear;
}

#menu li li:hover ul {
visibility: visible;
opacity: 1;
}

#menu .fa { margin-right: 5px; }

.logo {
width: 100%;
box-sizing: border-box;
}

.sidebar-icon {
float: right;
font-size: 25px;
color: #D0D5DD;
margin-top: -10px;
position: relative;
}

i.sidebar-navitem-tip {
    font-size: 12px;
    position: absolute;
    animation: color-change 1s alternate infinite;
    left: 40px;
    top: 32px;
}
@keyframes color-change {
    0% { color: #fba33c; }
    100% { color: #fff; }
}

i.new {
    font-size: 11px;
    animation: color-changing 1s alternate infinite;
    margin-left: 5px;
    top: -5px;
    position: relative;
}
@keyframes color-changing {
    0% { color: #fba33c; }
    100% { color: #000; }
}