@media only screen and (min-width: 320px) and (max-width: 812px) {
    #root {
        overflow-x: hidden;
    } 
    .left-panel, .video-container, .welcome-username, .shortlisting-tile .shortlisting, .arrow, .application-tile .application, .recharts-wrapper, .widget-cta-page-nopos, .file-approvedbg, .file-rejectedbg, .file-underreviewbg, .bot-frame, .chapters, .file-list table thead th:nth-child(2), .file-list table thead th:last-child, .btn-chat, .page-container.sidebar-collapsed .logo, .chat-icon-container {
        display: none !important;
    }
    .responsive-visible {
        display: block !important;
    }
    .header-logo {
        margin-left: 0;
    }
    .right-panel {
        width: auto;
        border-top: 100px solid #FBA33C;
        padding: 25px;
    }
    .body-panel {
        flex-direction: column;
        margin-right: 0;
        margin-left: 5px !important;
    }
    .filter-container {
        margin-right: 0;
        margin-left: 0;
    }
    .grid-list {
        flex-direction: column;
    }
    .grid-container .grid li.main-list, .grid-container .grid .list-detail li {
        width: 100%;
    }
    .dash-col {
        flex-direction: column;
    }
    .dash-col .dashboard-widget-frame {
        min-height: 105px;
        width: 100%;
    }
    .dashboard-widget-frame, .dash-block {
        width: 98%;
    }
    .counseling-tile, .document-tile, .loan-tile, .predeparture-tile, .postdeparture-tile, .coaching-tile {
        background-image: none !important;
    }
    .dashboard-tile-category .button-alignment {
        position: initial;
        margin-top: -10px;
        margin-bottom: 10px;
    }
    .dashboard-tile-category li {
        width: max-content !important;
        border-right: 0 !important;
    }
    .course-alt {
        margin-top: -50px;
    }
    ul.header-navigation {
        margin-right: 10px;
    }
    header.fixed-top.fixed-top.d-flex.justify-content-between {
        margin-left: 0px;
    }
    .no-filterpanel {
        margin-left: 10px;
        margin-top: 100px;
        margin-right: 10px;
    }
    .page-content > h1 {
        font-size: 25px;
    }
    .file-list {
        overflow: auto;
    }
    .file-list tbody td, .applicationDashboard tbody td {
        display: block;
    }
    .file-underreviewbg th, .applicationDashboard th {
        display: none;
    }
    .file-underreviewbg th:first-child {
        display: block;
    }
    .responsive-col .col {
        flex: auto;
    }
    .responsive-col .col-3 {
        width: 100%;
    }
    .responsive-col .col-9 {
        width: 100%;
    }
    .videoselected-thumb div.item-container {
        width: inherit !important;
    }
    .footer-links {
        margin-right: 0;
    }
    .grid-layout, .list-detail.list-header {
        display: none;
    }
    .uni-name {
        word-break: break-word;
    }
    .btn-hidefilter {
        margin-left: 0;
    }
    .scholarship-page {
        margin-left: 10px;
        margin-top: 100px;
    }
    .grid-container .grid .list-detail li.loan-name {
        display: block;
    }
    .settings-container {
        padding: 20px;
    }
    .settings-container.user-profile {
        margin-left: -65px;
    }
    .dashboard-widget-frame {
        width: auto;
    }
    .widget-cta-page {
        right: 0;
    }
    .d-flex.align-items-start.mt-3.application-profile {
        display: block !important;
        overflow-x: auto !important;
    }
    .tab-content {
        overflow-x: hidden;
    }
    .application-profile.nav-pills .nav-link {
        padding-left: 10px;
        padding-right: 10px;
    }
    #menu li a {
        font-size: 15px;
    }
    #menu li ul li a {
        font-size: 13px;
    }
    .page-container.sidebar-collapsed-back .sidebar-menu {
        width: 190px;
    }
    .col.mx-1.my-5.w-50 {
        width: 100% !important;
    }
    .actionBox .d-flex.justify-content-between {
        display: block !important;
    }
    .actionBox button {
        margin-bottom: 10px;
    }
    .button-alignment {
        position: initial !important;
    }
    .result-container {
        flex-wrap: wrap;
        width: min-content;
        margin: 0 auto;
    }
    .close-captions {
        right: -40%;
        top: -15% !important;
    }
    .test-card {
        width: -webkit-fill-available !important;
    }
    .test-desc table {
        display: none;
    }
    .sim-card td {
        display: block;
    }
    .job-roles-search, .search-container {
        flex-direction: column;
    }
    .housing .search-container {
        align-items: normal;
        width: 100%;
    }
    .page-container.sidebar-collapsed .sidebar-menu {
        display: flex;
        width: 100%;
        height: 50px;
        bottom: 0;
        top: auto;
    }
    #menu li {
        margin-top: 0;
        display: inline-flex;
        max-width: 16%;
    }
    #menu li ul.sub-menu {
        left: -40px;
        width: auto;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
    }
    #menu li ul li {
        max-width: none;
    }
    .page-container.sidebar-collapsed #menu li ul li span {
        opacity: 1;
        padding-left: 10px;
    }
    .file-approved:before, .file-rejected:before {
        width: auto;
    }
    .housing .selectBox {
        margin-top: -50px;
        position: absolute;
        right: 30px;
        width: 25%;
    }
    .roomImg {
        width: -webkit-fill-available;
    }
    .body-content.scholarship-details {
        margin-left: 0;
    }
    .scholarship-details-content {
        width: 100%;
    }
    .scholarship-details-list li {
        width: min-content;
    }
    .scholarship-details-content .form-button.mb-5.d-flex.justify-content-between {
        margin-left: 10px;
    }
    .scholarship-page .list-detail.grid li {
        display: flex;
    }
    .scholarship-page .list-detail.grid li .list-text-label {
        width: 50%;
    }
    .housing .cardBox.col div {
        display: flow;
    }
    button.custom-housing-btn {
        margin-top: 10px;
    }
    span.priceTag {
        font-size: 14px;
        display: inline-block;
        margin: 10px 0;
    }
    .d-flex.align-items-start.mt-3.application-profile-block, .grid-frame {
        flex-direction: column;
    }
    .application-profile-block .form-group.section-content .row, .referral-form .row {
        display: block;
    }
    .application-profile-block button.btn.btn-primary.updateBtn.mb-5 {
        margin: 0;
        margin-top: 10px;
    }
    .application-profile-block .nav.flex-column.nav-pills.me-3.application-profile {
        margin-bottom: 25px;
    }
    ul.user-menu {
        display: flex;
    }
    .marketing-email {
        flex-direction: column;
    }
    .template-list {
        width: max-content;
        background: #fff;
    }
    .marketing-email-content {
        width: 100%;
    }
    .course-list-item {
        width: auto;
    }
    main.d-flex.flex-row.course-form-container {
        flex-direction: column !important;
    }
    .chapters-list {
        width: 100%;
        margin-left: 0;
        margin-top: 20px;
    }
    .d-flex.lesson-container {
        flex-direction: column;
    }
    .d-flex.lesson-container .width-50 {
        width: 100%;
        margin-left: 0 !important;
    }
    .subsection-head {
        padding: 20px 0;
    }
    .subsection-head p {
        display: block;
        margin: 0;
        padding: 0;
        font-weight: 600 !important;
    }
    .page-title {
        font-size: 15px;
    }
    .custom-formfloating {
        width: 50%;
    }
    .profilecomp-status {
        display: inherit !important;
    }
    .dashboard-widget-frame {
        width: 100% !important;
        font-size: 13px;
    }
    .basic-plan {
        margin-top: 0px;
        display: block !important;
        font-size: 14px;
        text-align: center;
    }
    .time-limit {
        font-size: 14px;
    }
    .steps-modal {
        display: flex;
        flex-flow: column-reverse;
    }
    .skip-steps {
        display: block !important;
        width: 100%;
        text-align: right;
    }
    .profile-form {
        width: 100%;
        box-shadow: none;
    }
    .profile-form .col {
        flex: none;
        margin-bottom: 10px;
    }
    .profile-form .custom-formfloating {
        width: 100%;
    }
}