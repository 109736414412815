.settings-container {
    background: #fff;
    width: auto;
    overflow: hidden;
    margin-top: -25px;
    padding: 50px;
}
.settings-container h1 {
    color: #000;
    font-size: 23px;
}
.settings-container .section-head {
    color: #344054;
    font-size: 17px;
    margin: 0 0 10px 0;
}
.settings-container .profile-section {
    border-bottom: 1px dotted #98A2B3;
}
.settings-container .profile-section:last-child {
    border-bottom: 0;
}
.edit-section {
    margin: 25px;
    float: right;
    display: none;
}
.edit-section-inline {
    position: absolute;
    margin: 20px 100px !important;
}
.profile-block {
    margin-bottom: 25px;
    border: 1px solid #D0D5DD;
    border-radius: 10px;
    padding: 20px;
    float: left;
    margin-right: 20px;
}
.profile-block li {
    list-style-type: none;
    font-size: 14px;
    padding-bottom: 10px;
}
.profile-block li:first-child {
    font-size: 15px;
    font-weight: 600;
    color: #101828;
}
.profile-block li svg {
    margin-right: 5px;
}
.profile-block-inline {
    float: left;
    margin-right: 50px;
    width: 25%;
}
.profile-block-inline:last-child {
    margin-right: 0;
}
.profile-block.recent {
    border-color: #059200;
}
.edit-btn {
    cursor: pointer;
}
.edit-sectionIcons {
    margin-left: 50px;
}
.form-group.edit-form {
    clear: both;
}
.app-multiSelect{
    z-index: 9;
}
.no-filterpanel.public-view {
    margin-left: 20px;
}
.whatsapp-qr {
    width: 150px;
    height: 150px;
}