.main-body {
    background: #FFF;
}
.main-affiliate h1 {
    color: #000;
    font-weight: 400;
    font-size: 35px;
    margin-top: 100px;
}
.main-affiliate h2 {
    color: #000;
    font-weight: 400;
    font-size: 35px;
    margin-top: 25px;
}
.main-affiliate p {
    margin: 10px 0;
    font-size: 17px;
}
.how-it-works ul {
    margin: 0;
    padding: 0;
    margin-top: 50px;
}
.how-it-works ul li {
    list-style-type: none;
    display: table-cell;
    border-top: 1px dotted #000;
    padding-top: 25px;
}
.how-it-works ul li::before {
    content: '';
    width: 1.6rem;
    height: 1.6rem;
    position: relative;
    margin-top: -2.3rem;
    border-radius: 50%;
    background-color: #000;
    display: block;
    margin-bottom: 20px;
}
.section-title-regular {
    font-size: 20px;
    font-weight: 600;
}
.affiliate-faq {
    margin-top: 50px;
    margin-bottom: 50px;
}
.affiliate-faq .faq-c {
    margin-bottom: 0;
    cursor: pointer;
}
.ready-affiliate {
    margin-bottom: 20px;
}
.ready-affiliate a {
    text-decoration: underline;
}
.faq-c {
    margin-bottom: 2em;
    padding: 1em 0;
}
.faq-q {
    font-weight: 600;
}
.faq-t {
    line-height: 1em;
    color: #DC7215;
    float: right;
    -webkit-transition: all 200ms;
    -moz-transition: all 200ms;
    transition: all 200ms;
    font-size: 30px;
    border: 1px solid #DC7215;
    border-radius: 50%;
    padding: 0 5px;
    cursor: pointer;
}
.faq-o {
    transform: rotate(-45deg);
    transform-origin: 50% 50%;
    -ms-transform: rotate(-45deg);
    -ms-transform-origin: 50% 50%;
    -webkit-transform: rotate(-45deg);
    -webkit-transform-origin: 50% 50%;
    -webkit-transition: all 200ms;
    -moz-transition: all 200ms;
    transition: all 200ms;
}
.faq-a {
    display: none;
}
